<div [class.has-danger]="this.showValidationErrors && (!stateFormControl.valid || (stateFormControl.errors && (stateFormControl.errors['required'])))">
  <select [formControl]="stateFormControl" class="w-100 form-control custom-select">
    <option disabled selected value></option>
    <option *ngFor="let state of states" [value]="state.code">
      {{ state.toString() }}
    </option>
  </select>
  <small class="form-control-feedback"
    [hidden]="stateFormControl.valid || !(this.showValidationErrors && stateFormControl.errors && stateFormControl.errors['required'])"
    translate='ERRORS.STATE_REQUIRED'></small>
</div>
