import { Component, Input, OnInit } from '@angular/core';
import { StateCode } from '../../models/StateCode';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CountryRequiredInformationService } from '../../services/country-require-information.service';

@Component({
  selector: 'sk-state',
  templateUrl: './state.component.html',
})
export class StateComponent implements OnInit {
  @Input() showValidationErrors: boolean;
  @Input() controlName: string;
  @Input() states: StateCode[] = [];

  stateFormControl: FormControl;
  form: FormGroup;

  constructor(private formGroupDirective: FormGroupDirective, private countryRequiredInformationService: CountryRequiredInformationService) {}

  ngOnInit() {
    this.form = this.formGroupDirective.form;
    this.stateFormControl = this.formGroupDirective.form.get(this.controlName) as FormControl;
    this.stateFormControl.setValidators(this.stateValidator());
  }

  stateValidator(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors => {
      if (this.form && this.form.value && this.form.value.business) {
        const isCountrySelected = this.form.value.business && this.form.value.business.country;

        if (isCountrySelected && this.countryRequiredInformationService.isStateRequired(this.form.value.business.country) && control.value.trim() === '') {
          return { required: true };
        }
      }

      return null;
    }
  }
}
