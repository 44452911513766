import * as tslib_1 from "tslib";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
export function LanguageLoader(translate, cookieService) {
    return () => tslib_1.__awaiter(this, void 0, void 0, function* () {
        return new Promise(resolve => {
            // fallback language
            translate.setDefaultLang('en');
            const cookieLang = cookieService.get('skLanguageCode');
            const browserLang = cookieLang || translate.getBrowserLang();
            // TODO: Get together with UX. What are the standards for translations. Get an updated list of stuff.
            translate.use(browserLang.match(/en|ja|sq|de/) ? browserLang : 'en').subscribe(_ => resolve());
        });
    });
}
