<div display:flex>
  <div class="form-group">
    <div class="sk-select-flag">
      <i *ngIf="!!country && country.countryCode" class="flag"
        [ngClass]="(!country || !country.countryCode)?null:'flag-'+ country.countryCode.toLowerCase()"></i>
      <select class="form-control custom-select" [(ngModel)]="country">
        <option disabled selected value></option>
        <option *ngFor="let c of countries" [ngValue]="c"
          [attr.selected]="country && c.countryCode===country.countryCode ? '' : null">
          {{ c.translatedCountryName | async }}
           ({{c.countryCode}})
        </option>
      </select>
    </div>
  </div>
</div>