import { Contact } from './Contact';
import { Business } from './Business';

export class ContactMeRequest
{
    contact: Contact;
    business: Business;
    leadSource: string;
    leadSourceOther: string;
    leadReferralSource: string;
    leadType: string;
}