import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { default_countries } from './default-countries.json';
import { SkCountry } from './sk-country';
import { SkCountryFactoryService } from './sk-country-factory.service';

@Component({
  selector: 'sk-country-dropdown-with-flag',
  templateUrl: './sk-country-dropdown-with-flag.component.html',
  styleUrls: ['./sk-country-dropdown-with-flag.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SkCountryDropdownWithFlagComponent),
      multi: true
    }
  ]
})
export class SkCountryDropdownWithFlagComponent implements OnInit, ControlValueAccessor {

  @Input() displayCountries: string[] = null;
  @Input('country')
  set country(country: SkCountry) {
    if (country !== undefined) {
      // be sure to get the exact object that is assigned to the option.
      country = this.countries.find(c => c.countryCode === country.countryCode);
      if (this._country !== country) {
        this._country = country;
        this.onChange(country);
        this.onTouch(country);
      }
    }
  }
  get country() { return this._country; }

  countries: SkCountry[];
  _country: SkCountry;

  constructor(private readonly countryFactory: SkCountryFactoryService) {
    if (this.displayCountries === null) {
      this.displayCountries = default_countries;
    }
  }
  onChange: (country: SkCountry) => void = (country: SkCountry) => { }
  onTouch: (country: SkCountry) => void = (country: SkCountry) => { }

  writeValue(country: SkCountry): void {
    if (typeof country === 'string') {
      return;
    }
    this.country = country; // this.countries.find(country=>country.displayName === displayCountry);
  }
  registerOnChange(fn: (country: SkCountry) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: (country: SkCountry) => void): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.countries = this.displayCountries.map(dc => this.countryFactory.createCountry(dc));
  }


  SetCountry(country: SkCountry) {
    this.country = country;
  }
}
