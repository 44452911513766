import * as i0 from "@angular/core";
export class CountryRequiredInformationService {
    constructor() {
        this.countriesRequiringPostalCode = ['US', 'GB', 'DE', 'AU'];
        this.countriesRequiringState = ['US'];
    }
    isPostalCodeRequired(countryCode) {
        return this.countriesRequiringPostalCode.some(cc => cc === countryCode);
    }
    isStateRequired(countryCode) {
        return this.countriesRequiringState.some(cc => cc === countryCode);
    }
}
CountryRequiredInformationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CountryRequiredInformationService_Factory() { return new CountryRequiredInformationService(); }, token: CountryRequiredInformationService, providedIn: "root" });
