export class RegistrationRequest
{
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    companyName: string;
    phone: string;
    mobilePhone: string;
    role: number;
    numberOfEmployees: number;
    numberOfO365Licenses: number;
    experience: number;
    expectedO365Seats_12Months: number;
    interest: number;
    hearAboutUs: number;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    countryCode: string;
    postalCode: string;
}