import * as i0 from "@angular/core";
export class PostalCodeValidationService {
    constructor() {
        this.usaPostalCodeLength = 5;
        this.onlyDigitsRegex = /^\d+$/;
    }
    isValidUSAPostalCode(postalCode) {
        return postalCode.length === this.usaPostalCodeLength && this.onlyDigitsRegex.test(postalCode);
    }
}
PostalCodeValidationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PostalCodeValidationService_Factory() { return new PostalCodeValidationService(); }, token: PostalCodeValidationService, providedIn: "root" });
