import { AbstractControl } from '@angular/forms';

const EmailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export const ValidateEmail = (email: AbstractControl): { [key: string]: boolean } | null => {
  if (!EmailRegex.test(email.value)) {
    return { 'InvalidEmail': true }
  }

  return null;
}

/**
 * When the tab key is pressed, and google location suggestions dropdown is present, select the first item in the dropdown before
 * tabbing to the next field.
 * @param inputField The field this should be applied to. 
 * @param additionalFieldToClearBeforeSelectingFirstChoice [optional] In some cases, it is necessary to clear the 
 * field of the following tab stop. If this is not done, although the google location suggestions API fills in the subsequent field,
 * the dropdown choices for that field will be based on its previous value.
 */
export const selectFirstPlaceChoiceOnTabKey = <K extends keyof HTMLElementEventMap>(inputField: HTMLElement, additionalFieldToClearBeforeSelectingFirstChoice?: HTMLElement) => {
  // from Stack Overflow: https://stackoverflow.com/a/49620828

  inputField.addEventListener = getAddEventListenerWrapperFunction(inputField, 'keydown',
    (ev: HTMLElementEventMap[K],
      originalListener: (inputElement: HTMLInputElement, ev: HTMLElementEventMap[K]) => any) => {
      let keyboardEvent = ev as KeyboardEvent;
      // Simulate 'down arrow' if key press of tab and no value selected.
      const suggestionSelected = document.getElementsByClassName('pac-item-selected').length !== 0;
      let key = keyboardEvent.key.toLowerCase();
      if (key === 'tab'
        && !suggestionSelected
        && anElementOfCollectionIsVisible(document.getElementsByClassName('pac-container'))) {
        if (additionalFieldToClearBeforeSelectingFirstChoice) {
          (additionalFieldToClearBeforeSelectingFirstChoice as HTMLInputElement).value = "";
        }
        originalListener.apply(inputField, [downArrowEvent]);
      }
    });
  const downArrowEvent = new KeyboardEvent('keydown',
    {
      key: 'ArrowDown',
      code: 'ArrowDown',
      keyCode: 40
    } as any); // cast to any to avoid error caused by keyCode being deprecated
}

function getAddEventListenerWrapperFunction<K extends keyof HTMLElementEventMap>(inputField: HTMLElement,
  eventType: keyof HTMLElementEventMap,
  preListenerAction: (ev: HTMLElementEventMap[K],
    originalListener: (inputElement: HTMLElement, ev: HTMLElementEventMap[K]) => any)
    => any) {

  // Save the original event listener
  const originalAddEventListener = inputField.addEventListener;
  const result = (type: K,
    listenerToAdd: (el: HTMLElement, ev: HTMLElementEventMap[K]) => any, options?: boolean | AddEventListenerOptions): void => {

    if (type === eventType) {
      // Store existing listener function
      const originalListener = listenerToAdd;
      listenerToAdd = (el: HTMLElement, ev: HTMLElementEventMap[K]) => {
        ev = ev || <any>el;
        if (preListenerAction)
          preListenerAction(ev, originalListener);
        originalListener.apply(inputField, [ev]);
      }
    }

    originalAddEventListener.apply(inputField, [type, listenerToAdd]);
    return;
  }

  return result as any;

}

function anElementOfCollectionIsVisible(htmlElementCollection: HTMLCollectionOf<Element>) {
  return Array.from(htmlElementCollection).some((el: HTMLElement) => el.offsetParent != null);
}