import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PostalCodeValidationService {
  private usaPostalCodeLength = 5;
  private onlyDigitsRegex = /^\d+$/;

  isValidUSAPostalCode(postalCode: string): boolean {
    return postalCode.length === this.usaPostalCodeLength && this.onlyDigitsRegex.test(postalCode);
  }
}
