import { Component, OnInit } from '@angular/core';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(
    private intercom: Intercom
  ) { }

  async ngOnInit(): Promise<void> {

    this.intercom.boot({
        app_id: 'itg40p5r'
    });
  }
}
