import { usaStates } from '../utils/usa-statecodes.json';
export class UnitedStatesStateCode {
    constructor(name, code) {
        this.name = name;
        this.code = code;
    }
    /**
     * @summary USA state code in specific format.
     * @returns State code string.
     * @example Washington (WA)
     */
    toString() {
        return `${this.name} (${this.code})`;
    }
}
const ɵ0 = (stateInfo) => new UnitedStatesStateCode(stateInfo.name, stateInfo.code);
export const unitedStatesStateCodes = usaStates.map(ɵ0);
export { ɵ0 };
