import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  public logoutUrl = `https://${environment.prefix}portal.skykick.com/login`;

  submit() {
    sessionStorage.removeItem("MpnRequired");
    window.location.href = this.logoutUrl;
  }
}
