import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SkCountry } from './sk-country';

@Injectable({
  providedIn: 'root'
})
export class SkCountryFactoryService {

  constructor(private translate: TranslateService) {
  }

  createCountry(displayName: string = null): SkCountry{
    let country = new SkCountry(this.translate);
    if (displayName) {
      country.displayName = displayName;
      country.countryName = displayName.substring(0, displayName.length - 5);
      country.countryCode = displayName.substring(displayName.length - 3, displayName.length - 1);
    }
    return country;
  }
}