import { HttpClient } from '@angular/common/http';
import { ContactMeRequest } from '../models/ContactMeRequest';
import { Business } from '../models/Business';
import { Contact } from '../models/Contact';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ContactMeDataService {
    constructor(http) {
        this.http = http;
    }
    saveContactMe(contactMeRequest) {
        return this.http.post(`https://${environment.prefix}papi.skykick.com/lead`, contactMeRequest);
    }
    ConvertContactMeForm(form) {
        let contactMeRequest = new ContactMeRequest();
        contactMeRequest.contact = this.ConvertContactForm(form.contact);
        contactMeRequest.business = this.ConvertBusinessForm(form.business);
        contactMeRequest.leadSource = "Website";
        contactMeRequest.leadReferralSource = "register.skykick.com/contactme";
        contactMeRequest.leadSourceOther = "";
        contactMeRequest.leadType = "End Customer";
        return contactMeRequest;
    }
    ConvertContactForm(contactForm) {
        let contact = new Contact();
        let parsedName = this.ParseFullName(contactForm.fullName);
        contact.firstName = parsedName.firstName;
        contact.lastName = parsedName.lastName;
        contact.email = contactForm.email;
        contact.phoneNumber = contactForm.phoneNumber;
        contact.mobilePhoneNumber = contactForm.mobilePhoneNumber;
        return contact;
    }
    ParseFullName(fullName) {
        let splitted = fullName.split(" ");
        if (splitted.length != 2) {
            return {
                firstName: fullName,
                lastName: ""
            };
        }
        return {
            firstName: splitted[0],
            lastName: splitted[1],
        };
    }
    ConvertBusinessForm(businessForm) {
        let business = new Business();
        business.companyName = businessForm.companyName;
        business.address1 = businessForm.address;
        business.city = businessForm.city;
        business.postalCode = businessForm.postalCode;
        business.stateOrProvince = businessForm.state;
        business.country = businessForm.country;
        if (businessForm.migrationInterest == true) {
            business.migrationInterest = true;
        }
        else {
            business.migrationInterest = false;
        }
        if (businessForm.backupInterest == true) {
            business.backupInterest = true;
        }
        else {
            business.backupInterest = false;
        }
        if (businessForm.cloudManagerInterest == true) {
            business.cloudManagerInterest = true;
        }
        else {
            business.cloudManagerInterest = false;
        }
        if (business.migrationInterest && businessForm.emailMigrationRange != undefined) {
            business.emailMigrationRange = businessForm.emailMigrationRange;
        }
        if (business.backupInterest && businessForm.backupUserRange != undefined) {
            business.backupUserRange = businessForm.backupUserRange;
        }
        if (business.migrationInterest && businessForm.emailEnvironment != undefined) {
            business.emailEnvironment = +businessForm.emailEnvironment;
        }
        return business;
    }
}
ContactMeDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContactMeDataService_Factory() { return new ContactMeDataService(i0.ɵɵinject(i1.HttpClient)); }, token: ContactMeDataService, providedIn: "root" });
