<nav class="navbar fixed-top sk-header sk-reg">
  <div class="container sk-reg">
    <div class="sk-logo sk-reg p-0">
      <a href="https://www.skykick.com/" id="sk-registerNoGenPageAttributePartnerLogo">
        <img id="logo" src="../assets/images/skykick-logo.svg">
      </a>
    </div>
  </div>
</nav>


<div class="container sk-content sk-reg">
  <h1 class="display-3 text-center my-5" translate="WHO_ARE_YOU.LETS_GET_STARTED"></h1>
  <div class="container sk-reg card sk-card">
    <div class="row">
      <div class="col-sm-12 text-center my-5">
        <h3 translate="WHO_ARE_YOU.DESCRIPTION"></h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <form class="form-inline" [formGroup]="whoAreYouForm">
          <div class="col-md-6 text-center custom-control custom-radio sk-radio d-flex">
            <div class="justify-content-center">
              <img class="pb-100 d-none mx-auto d-lg-block d-xl-block" src="../assets/images/msp_illustration.svg">
              <input type="radio" id="ispRadio" name="pickerRadio" class="custom-control-input"
                formControlName="pickerRadio" value='isp'>
              <label class="custom-control-label font-medium" for="ispRadio" translate="WHO_ARE_YOU.ISP_RADIO"></label>
            </div>
          </div>
          <div class="mb-5 d-lg-none g-xl-none"></div>
          <div class="col-md-6 text-center custom-control custom-radio sk-radio d-flex">

            <div class="justify-content-center">
              <img class="pb-100 d-none mx-auto d-lg-block d-xl-block" src="../assets/images/other_illustration.svg">
              <input type="radio" id="otherRadio" name="pickerRadio" class="custom-control-input"
                formControlName="pickerRadio" value='other'>
              <label class="custom-control-label font-medium" for="otherRadio"
                translate="WHO_ARE_YOU.OTHER_RADIO"></label>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row justify-content-center mt-5" *ngIf="!this.isFormValid">
      <div class="col-md-5 text-center">
        <small class="d-flex align-items-center ml-5">
          <i class="material-icons md-22 icon-danger">error</i> <span class="text-danger ml-50"
            translate="ERRORS.WHO_ARE_YOU_REQUIRED"></span>
        </small>
      </div>
    </div>
    <div class="row my-5 justify-content-center">
      <div class="col-md-3">
        <button class="btn btn-lg btn-primary btn-block" type="submit" translate="WHO_ARE_YOU.NEXT"
          (click)="onSubmit()"></button>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>