import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class AppComponent {
    constructor(intercom) {
        this.intercom = intercom;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.intercom.boot({
                app_id: 'itg40p5r'
            });
        });
    }
}
