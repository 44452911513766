import { StateCode } from './StateCode';
import { usaStates } from '../utils/usa-statecodes.json';

export class UnitedStatesStateCode implements StateCode, Object {
  name: string;
  code: string;

  constructor(name: string, code: string) {
    this.name = name;
    this.code = code;
  }

  /**
   * @summary USA state code in specific format.
   * @returns State code string.
   * @example Washington (WA)
   */
  toString(): string {
    return `${this.name} (${this.code})`;
  }
}

export const unitedStatesStateCodes: UnitedStatesStateCode[] = usaStates.map((stateInfo: { name: string; code: string; }) => new UnitedStatesStateCode(stateInfo.name, stateInfo.code));
