import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators'
import { Observable } from 'rxjs';

export class SkCountry {
  private _countryName: string;
  displayName: string;
  countryCode: string;
  translatedCountryName: Observable<string>;

  public get countryName(): string {
    return this._countryName;
  }
  public set countryName(value: string) {
    if (value === this._countryName) {
      return;
    }
    this._countryName = value;
    let translationKey = value.replace(/(\w*)(\W)/g, '$1_').toUpperCase();
    this.translatedCountryName = this.translate.get(`COUNTRY.${translationKey}`)
      .pipe(tap(x => console.log(x)));
  }

  constructor(private translate: TranslateService) {
  }
}
