<nav class="navbar fixed-top sk-header sk-reg">
  <div class="container sk-reg">
    <div class="sk-logo sk-reg p-0">
      <a href="https://www.skykick.com/" id="sk-registerNoGenPageAttributePartnerLogo">
        <img id="logo" src="../assets/images/skykick-logo.svg">
      </a>
    </div>
  </div>
</nav>
<div class="container sk-content sk-reg my-10">
  <h1 class="display-3 text-center my-5"></h1>
<div class="container sk-reg card sk-card">
  <div class="row">
    <div class="col-sm-12 text-center my-5">
      <img class="pb-100"  src="../assets/images/art-reg-success.svg">
      <h2 class="my-3" translate="CONTACT_ME.THANK_YOU.TITLE"></h2>
      <span class="my-4 sk-body-display-1" translate="CONTACT_ME.THANK_YOU.SUB_DESC"></span>
      <div class="mt-4">
        <a class="font-medium" id="o365infolink" href="https://offers.skykick.com/smb-moving-guide-reg" target='_blank' translate="CONTACT_ME.THANK_YOU.O365_MOVING_GUIDE"></a>
      </div>
      <span class="my-2 sk-body-display-1" translate="CONTACT_ME.THANK_YOU.O365_MOVING_DESC"></span>
      <div class="mt-4">
        <a class="font-medium" id="backupprotectlink" href="https://offers.skykick.com/whyoffice365needsbackup" target='_blank' translate="CONTACT_ME.THANK_YOU.PROTECT_O365"></a>
      </div>
      <span class="my-2 sk-body-display-1" translate="CONTACT_ME.THANK_YOU.PROTECT_DESC"></span>
    </div>
  </div>
  <div class="row mb-5 justify-content-center">
    <div class="col-md-3">
      <a class="btn btn-md btn-primary btn-block" id="homepageLink" href="https://skykick.com" type="submit" translate="CONTACT_ME.THANK_YOU.RETURN_TO_HOMEPAGE"></a>
    </div>
  </div>
</div>
<app-footer></app-footer>
</div>
