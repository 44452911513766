import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ThrowStmt } from '@angular/compiler';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-who-are-you',
  templateUrl: './who-are-you.component.html',
  styleUrls: ['./who-are-you.component.scss']
})
export class WhoAreYouComponent implements OnInit {

  constructor(private readonly formBuilder: FormBuilder,
    private readonly cookieService: CookieService,
    private readonly translationService: TranslateService,
    private readonly router: Router,
    private route: ActivatedRoute) { }

  public whoAreYouForm = this.formBuilder.group({
    pickerRadio : []
  });

  public isFormValid: boolean = true;

  ngOnInit() {
  }

  onSubmit() {
    const contactMeFormData = this.whoAreYouForm.getRawValue();

    if(contactMeFormData.pickerRadio === null){
      this.isFormValid = false;
      return;
    }

    if (contactMeFormData.pickerRadio === 'isp') {
      this.router.navigate(['register']);
    }
    else {
      this.router.navigate(['contactme']);
    }
  }

}
