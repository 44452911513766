import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear: string;
  environmentPrefix: string;
  constructor(private readonly translationService: TranslateService, private readonly cookieService: CookieService) {
    this.currentYear = String(new Date().getFullYear());
    this.environmentPrefix = environment.prefix;
  }

  ngOnInit() {
  }

  changeLanguage(languageCode: string) {
    var expirationDate = new Date();
    this.cookieService.set('skLanguageCode', languageCode, new Date(expirationDate.setFullYear(expirationDate.getFullYear() + 10)), "/", null, false, "Lax");
    window.location.reload();
  }
}
