export class Business
{
    companyName: string;
    address1: string;
    city: string;
    stateOrProvince: string;
    postalCode: string;
    country: string;
    migrationInterest: boolean;
    backupInterest: boolean;
    cloudManagerInterest: boolean;
    emailMigrationRange: number;
    backupUserRange: number;
    emailEnvironment: number;
}