import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ContactMeRequest } from '../models/ContactMeRequest';
import { Observable } from 'rxjs';
import { ContactMeForm } from '../models/ContactMeForm';
import { Business } from '../models/Business';
import { Contact } from '../models/Contact';
import { ContactForm } from '../models/ContactForm';
import { BusinessForm } from '../models/BusinessForm';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ContactMeDataService {
    

    constructor(private http: HttpClient) { }

    public saveContactMe(contactMeRequest: ContactMeRequest) : Observable<any> {
        return this.http.post<any>(`https://${environment.prefix}papi.skykick.com/lead`, contactMeRequest);
    }

    public  ConvertContactMeForm(form: ContactMeForm): ContactMeRequest {
        let contactMeRequest =  new ContactMeRequest();

        contactMeRequest.contact = this.ConvertContactForm(form.contact);
        contactMeRequest.business = this.ConvertBusinessForm(form.business);
        contactMeRequest.leadSource = "Website";
        contactMeRequest.leadReferralSource = "register.skykick.com/contactme";
        contactMeRequest.leadSourceOther = "";
        contactMeRequest.leadType = "End Customer";
        
        return contactMeRequest;
    }

    private ConvertContactForm(contactForm: ContactForm): Contact {
        let contact = new Contact();
        let parsedName = this.ParseFullName(contactForm.fullName);

        contact.firstName = parsedName.firstName;
        contact.lastName = parsedName.lastName;
        contact.email = contactForm.email;
        contact.phoneNumber = contactForm.phoneNumber;
        contact.mobilePhoneNumber = contactForm.mobilePhoneNumber;

        return contact;
    }

    private ParseFullName(fullName: string): {firstName: string, lastName: string}{
        let splitted = fullName.split(" ");

        if (splitted.length != 2)
        {
            return {
                firstName: fullName,
                lastName: ""
            }
        }
        return {
            firstName:  splitted[0],
            lastName: splitted[1],
        }
    }

    private ConvertBusinessForm(businessForm: BusinessForm): Business {
        let business = new Business();

        business.companyName = businessForm.companyName;
        
        business.address1 = businessForm.address;
        business.city = businessForm.city;
        business.postalCode = businessForm.postalCode;
        business.stateOrProvince = businessForm.state;
        business.country = businessForm.country;

        if (businessForm.migrationInterest == true) {
            business.migrationInterest = true;
        }
        else {
            business.migrationInterest = false;
        }

        if (businessForm.backupInterest == true) {
            business.backupInterest = true;
        }
        else {
            business.backupInterest = false;
        }

        if (businessForm.cloudManagerInterest == true) {
            business.cloudManagerInterest = true;
        }
        else {
            business.cloudManagerInterest = false;
        }

        if (business.migrationInterest && businessForm.emailMigrationRange != undefined) {
            business.emailMigrationRange = businessForm.emailMigrationRange;
        }

        if (business.backupInterest && businessForm.backupUserRange != undefined) {
            business.backupUserRange = businessForm.backupUserRange;
        }

        if (business.migrationInterest && businessForm.emailEnvironment != undefined) {
            business.emailEnvironment = +businessForm.emailEnvironment;
        }

        return business;
    }
}


