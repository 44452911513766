import { SkCountry } from './sk-country';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export class SkCountryFactoryService {
    constructor(translate) {
        this.translate = translate;
    }
    createCountry(displayName = null) {
        let country = new SkCountry(this.translate);
        if (displayName) {
            country.displayName = displayName;
            country.countryName = displayName.substring(0, displayName.length - 5);
            country.countryCode = displayName.substring(displayName.length - 3, displayName.length - 1);
        }
        return country;
    }
}
SkCountryFactoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SkCountryFactoryService_Factory() { return new SkCountryFactoryService(i0.ɵɵinject(i1.TranslateService)); }, token: SkCountryFactoryService, providedIn: "root" });
