import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountryRequiredInformationService {
  private countriesRequiringPostalCode: ReadonlyArray<string> = ['US', 'GB', 'DE', 'AU'];
  private countriesRequiringState: ReadonlyArray<string> = ['US'];

  public isPostalCodeRequired(countryCode: string):boolean{
    return this.countriesRequiringPostalCode.some(cc => cc === countryCode);
  }

  public isStateRequired(countryCode: string) {
    return this.countriesRequiringState.some(cc => cc === countryCode);
  }
}
