<nav class="navbar fixed-top sk-header sk-reg">
    <div class="container sk-reg">
      <div class="sk-logo sk-reg p-0">
        <a href="https://www.skykick.com/" id="sk-registerNoGenPageAttributePartnerLogo">
          <img id="logo" src="../assets/images/skykick-logo.svg">
        </a>
      </div>
    </div>
  </nav>

  <div class="container sk-content sk-reg">
    <h1 class="display-3 text-center mt-5" translate="CONTACT_ME.TITLE"></h1>
    <h3 class="text-center m-3 font-weight-normal mb-5" translate="CONTACT_ME.SUBTITLE"></h3>

    <div class="alert alert-dismissible sk-alert-icon-24 alert-danger d-flex mb-100" *ngIf="showPlatformError">
      <i class="material-icons md-22 pull-xs-left">error_outline</i>
      <div class="ml-100">
        <span class="sk-alert-text" [innerHTML]="'ERRORS.PLATFORM_ERROR' | translate"></span>
      </div>
      <button type="button" class="close" (click)="closePlatformError()">
        <i class="material-icons md-18">close</i>
      </button>
    </div>

    <div class="container sk-reg card sk-card">
      <div class="row m-b-2">
        <div class="col-sm-12">

          <div class="py-4 px-0 px-lg-5">
            <div class="body-display-1" translate="CONTACT_ME.DESCRIPTION"></div>
            <form [formGroup]="contactMeForm" class="ng-pristine ng-valid ng-scope ng-valid-required ng-valid-min"
              (ngSubmit)="onSubmit()">
              <div formGroupName="contact" class="mt-5 mb-3">
                <h3 class="mb-3" translate="CONTACT_ME.CONTACT_INFORMATION.TITLE"></h3>
                <div class="row form-group">
                  <div class="col-md-6 pb-3 pb-md-0" [class.has-danger]="this.showValidationErrors && !this.fullNameError.valid">
                    <label translate="CONTACT_ME.CONTACT_INFORMATION.NAME"></label>
                    <input id="sk-contactname" formControlName="fullName" type="text" class="form-control" maxlength="50" tabindex=10>
                    <small class="form-control-feedback" [hidden]="this.showValidationErrors && this.fullNameError.valid">{{this.fullNameError.message}}</small>
                  </div>
                  <div class="col-md-6" [class.has-danger]="this.showValidationErrors && !this.emailError.valid">
                    <label class="control-label" translate="CONTACT_ME.CONTACT_INFORMATION.EMAIL"></label>
                    <input id="sk-email" formControlName="email" type="text" class="form-control" maxlength="50" tabindex=20>
                    <small class="form-control-feedback" [hidden]="this.showValidationErrors && this.emailError.valid">{{this.emailError.message}}</small>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-md-6 pb-3 pb-md-0" [class.has-danger]="this.showValidationErrors && !this.phoneNumberError.valid">
                    <label translate="CONTACT_ME.CONTACT_INFORMATION.PHONE"></label>
                    <input id="sk-phonenumber" formControlName="phoneNumber" type="tel" class="form-control" maxlength="25" tabindex=30>
                      <small class="form-control-feedback" [hidden]="this.showValidationErrors && this.phoneNumberError.valid">{{this.phoneNumberError.message}}</small>
                      <small class="text-muted" translate="COMMON.COUNTRY_CODE"></small>
                  </div>
                  <div class="col-md-6">
                    <label translate="CONTACT_ME.CONTACT_INFORMATION.MOBILE"></label>
                    <small class="sk-label-text text-muted float-right" translate="COMMON.OPTIONAL"></small>
                    <input id="sk-mobilephonenumber" formControlName="mobilePhoneNumber" type="tel" class="form-control"  maxlength="25" tabindex=40>
                  </div>
                </div>
              </div>
              <div formGroupName="business" class="mt-5 mb-3">
                <h3 class="mb-3" translate="CONTACT_ME.BUSINESS_PROFILE.TITLE"></h3>
                <div class="row form-group">
                  <div class="col-md-12" [class.has-danger]="this.showValidationErrors && !this.companyNameError.valid">
                    <label translate="CONTACT_ME.BUSINESS_PROFILE.NAME"></label>
                    <input id="sk-companyname" formControlName="companyName" type="text" class="form-control"
                      ngx-google-places-autocomplete [options]='options.Api' #placesRef="ngx-places"
                      (onAddressChange)="handleBusinessNameChange($event)" placeholder="" maxlength="50" tabindex=50>
                    <small class="form-control-feedback"
                      [hidden]="this.showValidationErrors && this.companyNameError.valid">{{this.companyNameError.message}}</small>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-md-12" [class.has-danger]="this.showValidationErrors && !this.addressError.valid">
                    <label translate="CONTACT_ME.BUSINESS_PROFILE.ADDRESS"></label>
                    <input id="sk-address" formControlName="address" type="text" class="form-control" ngx-google-places-autocomplete
                      [options]='options.AddressApi' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"
                      placeholder="" maxlength="50" tabindex=60>
                    <small class="form-control-feedback"
                      [hidden]="this.showValidationErrors && this.addressError.valid">{{this.addressError.message}}</small>
                  </div>
                </div>
                <div class="row form-group mb-5">
                  <div class="col-md-6 pb-3 pb-md-0" [class.has-danger]="this.showValidationErrors && !this.cityError.valid">
                    <label translate="CONTACT_ME.BUSINESS_PROFILE.CITY"></label>
                    <input id="sk-city" formControlName="city" type="text" class="form-control" ngx-google-places-autocomplete
                      [options]='options.CityApi' #placesRef="ngx-places" (onAddressChange)="handleCityChange($event)" placeholder=""
                      maxlength="50" tabindex=70>
                    <small class="form-control-feedback"
                      [hidden]="this.showValidationErrors && this.cityError.valid">{{this.cityError.message}}</small>
                  </div>
                  <div class="col-md-6 pb-3">
                    <label translate="CONTACT_ME.BUSINESS_PROFILE.STATE"></label>
                    <ng-container *ngIf="isUnitedStates; else notUSStateCodeField">
                      <sk-state controlName="business.state" [showValidationErrors]="this.showValidationErrors" [states]="usStates"></sk-state>
                    </ng-container>
                    <ng-template #notUSStateCodeField>
                      <input id="sk-state" formControlName="state" type="text" class="form-control" ngx-google-places-autocomplete
                        [options]='options.StateApi' #placesRef="ngx-places" (onAddressChange)="handleStateChange($event)" placeholder=""
                        maxlength="50" tabindex=80>
                    </ng-template>
                  </div>
                  <div class="col-md-6 pb-3" [class.has-danger]="(this.showValidationErrors && !this.postalCodeError.valid) || this.contactMeForm.get('business.postalCode').invalid">
                    <label translate="CONTACT_ME.BUSINESS_PROFILE.ZIP"></label>
                    <input id="sk-postalCode" formControlName="postalCode" type="text" class="form-control" placeholder="" maxlength="50"
                      tabindex=75>
                    <small class="form-control-feedback"
                      [hidden]="this.showValidationErrors && this.postalCodeError.valid">{{this.postalCodeError.message}}</small>
                    <small class="form-control-feedback"
                      [hidden]="!(this.contactMeForm.get('business.postalCode').errors && this.contactMeForm.get('business.postalCode').errors['fiveDigitsRequired'])"
                      translate="ERRORS.ENTER_FIVE_DIGIT_POSTAL_CODE"></small>
                  </div>
                  <input id="sk-country" formControlName="country" type="text" class="form-control" placeholder="" maxlength="50"
                    hidden>
                  <div class="col-md-6" [class.has-danger]="this.showValidationErrors && !this.countryError.valid">
                    <label translate="CONTACT_ME.BUSINESS_PROFILE.COUNTRY"></label>
                    <sk-country-dropdown-with-flag id="sk-displayCountry" [(ngModel)]="country" formControlName="displayCountry"
                      tabindex=87>
                    </sk-country-dropdown-with-flag>
                    <small class="form-control-feedback"
                      [hidden]="this.showValidationErrors && this.countryError.valid">{{this.countryError.message}}</small>
                  </div>
                  </div>
                <div class="form-group" [class.has-danger]="this.showValidationErrors && !this.interestError.valid">
                  <label class="mb-3" translate="CONTACT_ME.PRODUCT_INTEREST.TITLE"></label>
                  <div class="custom-control custom-checkbox sk-checkbox">
                    <input formControlName="migrationInterest" type="checkbox" class="custom-control-input"
                      id="skmigrationinterestchkbx">
                    <label class="custom-control-label" for="skmigrationinterestchkbx">
                      <div>
                        <h5>
                          <span translate="CONTACT_ME.PRODUCT_INTEREST.EMAIL.TITLE"></span>
                        </h5>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="form-group flex-fill pl-200" *ngIf="this.contactMeForm.value.business.migrationInterest">
                  <label translate="CONTACT_ME.PRODUCT_INTEREST.EMAIL.RANGE"></label>
                  <div class="d-flex" [class.has-danger]="this.showValidationErrors && !this.emailMigrationRangeError.valid">
                    <div class="d-flex flex-fill flex-column">
                      <input type="range" id="sk-emailrange" formControlName="emailMigrationRange" class="custom-range" min="0"
                        max="{{this.options.MailboxRange.length - 1}}" value="0" tabindex=100>
                      <div class="sk-range-key d-sm-none d-md-flex" style="display:none">
                        <div class="sk-tick-mark" *ngFor="let option of this.options.MailboxRange">
                          <div class="tick-label" translate="{{option}}"></div>
                        </div>
                      </div>
                      <small class="form-control-feedback"
                        [hidden]="(!this.showValidationErrors && this.emailMigrationRangeError.valid)">{{this.emailMigrationRangeError.message}}</small>
                    </div>
                    <div class="sk-range-value"
                      translate="{{this.options.MailboxRange[this.contactMeForm.value.business.emailMigrationRange]}}">}</div>
                  </div>
                </div>
                <div class="form-group pl-200" *ngIf="this.contactMeForm.value.business.migrationInterest"
                  [class.has-danger]="this.showValidationErrors && !this.emailEnvironmentError.valid">
                  <legend translate="CONTACT_ME.PRODUCT_INTEREST.EMAIL.ENVIRONMENT"></legend>
                  <div class="custom-control custom-radio sk-radio"
                    *ngFor="let option of this.options.EmailEnvironment; let i = index">
                    <input type="radio" id="sk-emailenvironment" class="custom-control-input" value="{{i}}"
                      formControlName="emailEnvironment" id="{{ 'radio' + i }}">
                    <label class="custom-control-label" for="{{ 'radio' + i }}" translate="{{option}}"></label>
                  </div>
                  <small class="form-control-feedback"
                    [hidden]="!this.showValidationErrors && this.emailEnvironmentError.valid">{{this.emailEnvironmentError.message}}</small>
                </div>
                <div class="form-group pt-3" [class.has-danger]="this.showValidationErrors && !this.interestError.valid">
                  <div class="custom-control custom-checkbox sk-checkbox">
                    <input formControlName="backupInterest" type="checkbox" class="custom-control-input" id="skbackupinterestchkbx">
                    <label class="custom-control-label" for="skbackupinterestchkbx">
                      <div>
                        <h5>
                          <span translate="CONTACT_ME.PRODUCT_INTEREST.BACKUP.TITLE"></span>
                        </h5>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="form-group flex-fill --has-danger pl-200" *ngIf="this.contactMeForm.value.business.backupInterest">
                  <label translate="CONTACT_ME.PRODUCT_INTEREST.BACKUP.RANGE"></label>
                  <div class="d-flex" [class.has-danger]="this.showValidationErrors && !this.backupUserRangeError.valid">
                    <div class="d-flex flex-fill flex-column">
                      <input id="sk-backuprange" formControlName="backupUserRange" type="range" class="custom-range" min="0"
                        max="{{this.options.MailboxRange.length - 1}}" value="0">
                      <div class="sk-range-key d-sm-none d-md-flex" style="display:none">
                        <div class="sk-tick-mark" *ngFor="let option of this.options.MailboxRange">
                          <div class="tick-label" translate="{{option}}"></div>
                        </div>
                      </div>
                      <small class="form-control-feedback"
                        [hidden]="this.showValidationErrors && this.backupUserRangeError.valid">{{this.backupUserRangeError.message}}</small>
                    </div>
                    <div class="sk-range-value"
                      translate="{{this.options.MailboxRange[this.contactMeForm.value.business.backupUserRange]}}"></div>
                  </div>
                </div>
                <small class="d-flex align-items-center mr-100"><i [hidden]="!this.showValidationErrors || this.interestError.valid"
                    class="material-icons md-22 icon-danger">error</i> <span
                    [hidden]="!this.showValidationErrors || this.interestError.valid" class="text-danger ml-50"
                    translate="ERRORS.ONE_PRODUCT_SELECTION_REQUIRED"></span></small>
              </div>
              <div class="form-group mt-5" [class.has-danger]="this.showValidationErrors && !this.termsAndConditionsError.valid">
                <div class="custom-control custom-checkbox sk-checkbox">
                  <input formControlName="termsAndConditions" type="checkbox" id="tandcchkbx"
                    class="custom-control-input" />
                  <label class="custom-control-label" for="tandcchkbx"><span [innerHTML]="'COMMON.TERMS_AND_CONDITIONS' | translate:{ termsUrl: 'https://www.skykick.com/terms-conditions/', privacyUrl: 'https://www.skykick.com/privacy-policy' }"></span></label>
                </div>
              </div>
              <button id="sk-submit" [disabled]="btnDisabled" class="btn btn-lg btn-primary btn-block" type="submit" translate="CONTACT_ME.SUBMIT"></button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
